import { createMuiTheme } from '@material-ui/core/styles';

// Colour Profiles
const eCBlue = {
    main : '#117AF3',
    contrastText : '#F3F5F9',
    lighter : '#E3EAF9'
}
const eCGreen = {
    main : '#11CC93',
    contrastText : '#F3F5F9'
}
const eCPink = {
    main : '#FD2D55',
    contrastText : '#F3F5F9'
}
const eCWhite = {
    main : '#FFFFFF',
    contrastText : '#1A1B33'
}
const eCLight = {
    main : '#F3F5F9',
    contrastText : '#1A1B33'
}
const eCDark = {
    primary : '#0A1731',
    contrastText : '#F3F5F9'
}
  
// Theme
const eCTheme = createMuiTheme({
    palette : {
        primary : eCBlue,
        secondary : eCGreen,
        error: eCPink,
        text: eCDark,
        background: {
          paper: eCWhite.main,
          default: eCLight.main
        },
        type: 'light'
    },
    shape : {
      borderRadius : 4
    },
    breakpoints : {
        values : {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1200,
          xl : 1600
        }
    },
    overrides: {
        MuiCssBaseline: {
          '@global': {
            html: {
              scrollBehavior: 'smooth',
            },
          },
        },
    },
    typography : {
        fontFamily : 'Inter, Helvetica, Arial, sans-serif',
        h1: {
            fontWeight: 600,
            fontSize: '3rem' //48
        },
        h2 : {
            fontWeight: 600,
            fontSize: '2.25rem' //36
        },
        h3 : {
            fontWeight: 600,
            fontSize: '1.75rem' //28
        },
        h4 : {
            fontWeight: 600,
            fontSize: '1.5rem' //24
        },
        h5 : {
            fontWeight: 600,
            fontSize: '1.2rem'
        },
        subtitle1 : {
            fontWeight: 400,
            fontSize: `1.87rem`
        },
        subtitle2 : {
            fontWeight: 700,
            fontSize: `1.87rem`
        },
        body1 : {
            fontWeight: 400,
            fontSize: `1rem` //16
        },
        body2 : {
            fontWeight: 600,
            fontSize: `1rem` //16
        }
    }

})
// console.log(eCTheme);

export { eCBlue, eCGreen, eCPink, eCWhite, eCLight, eCDark, eCTheme }