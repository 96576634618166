import React, { useReducer, useContext } from "react"
import AuthReducer from "./authReducer"
import AuthContext from "./authContext"
import AxiosRequest from "../../services/AxiosRequests"
import AlertContext from "../alert/alertContext"
import { navigate } from "gatsby"
import Cookies from "js-cookie"
import { axiosInstance } from "../../services/AxiosRequests"

import {
  SET_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOG_OUT_SUCCESS,
  AUTH_SUCCESS,
  AUTH_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  ACCOUNT_ACTIVATE_SUCCESS,
  ACCOUNT_ACTIVATE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  ACTION_SUCCESS,
} from "../types"

const AuthState = props => {
  const initialState = {
    user: null,
    isAuthenticated: false,
    loading: false,
    isLoading: false,
    expired: false
  }

  const [state, dispatch] = useReducer(AuthReducer, initialState)
  const alertContext = useContext(AlertContext)
  const { setAlert } = alertContext

  let authToken = Cookies.get("token")
 

  const errMsg = "An error occured, please try again"
  // const authMsg = "Session expired, please log in to continue"

  //Login a user
  const Login = async payload => {
    setLoading()
    try {
      const res = await AxiosRequest("post", "api/Account/login", payload)
      if (res.data.requestSuccessful) {
        const { user, token, expires, isUser } = res.data.responseData
        if(isUser){
          let date = new Date(); //token issue time
          let valid = new Date(); 
  
          // 5 minutes allowance
          const validMinutes = Number((expires/60)-5)
          // const validMinutes = 2
  
          valid.setMinutes(date.getMinutes()+validMinutes) //expiry time
  
          await dispatch({
            type: LOGIN_SUCCESS,
            payload: { user, expires, valid },
          })
          Cookies.set("token", token) //instead of localstorage
          axiosInstance.defaults.headers.Authorization = `Bearer ${token}`
          navigate("/dashboard")
        }else{
          setAlert("Login Unsuccessful", "error")
          actionSuccess()
        }
      } else {
        dispatch({
          type: LOGIN_FAIL,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: LOGIN_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }

  //load a user
  const loadUser = async () => {
      setLoading()
      if(authToken){
        axiosInstance.defaults.headers.Authorization = `Bearer ${authToken}`
      }
      try {
        const res = await AxiosRequest(
          "get",
          `/api/User/GetUserByEmail/${localStorage.email}`
        )
        if (res.data.requestSuccessful) {
          dispatch({
            type: AUTH_SUCCESS,
            payload: res.data.responseData,
          })
        }else{
          dispatch({
            type: AUTH_ERROR,
          })
        }
      } catch (err) {
        // console.log(err)
        dispatch({
          type: AUTH_ERROR,
        })
      }
  }

  //register a user
  const register = async payload => {
    setLoading()
    try {
      const res = await AxiosRequest("post", "api/Account/Register", payload)
      if (res.data.requestSuccessful) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data.responseData
        })

        navigate("/user/verify")
      } else {
        dispatch({
          type: REGISTER_FAIL,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: REGISTER_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }

  //Send Activation Email
  const sendActivateEmail = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", `api/User/ResendEmailConfirmation/${state.user.id}`)
      if (res.data.requestSuccessful) {
        await dispatch({
          type: RESEND_EMAIL_SUCCESS,
        })
        setAlert("Email sent successfully", "success")
      } else {
        dispatch({
          type: RESEND_EMAIL_FAIL,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: RESEND_EMAIL_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }


  //Activate user's email
  const activateUserEmail = async payload => {
    setLoading()
    try {
      const res = await AxiosRequest("post", "api/Account/verifyaccount", payload)
      if (res.data.requestSuccessful) {
        await dispatch({
          type: ACCOUNT_ACTIVATE_SUCCESS,
        })
        navigate("/user/login")
        setAlert("Email confirmed successfully, login to continue", "success")
      } else {
        dispatch({
          type: ACCOUNT_ACTIVATE_FAIL,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ACCOUNT_ACTIVATE_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }

 

  const updateProfile = async payload => {
      dispatch({ type: UPDATE_PROFILE })
    try {
      const res = await AxiosRequest("put", "/api/User/Update", payload)
      if (res.data.requestSuccessful) {
        await dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: res.data.responseData
        })
        setAlert("Profile updated successfully", "success")
      }else{
        dispatch({
          type: UPDATE_PROFILE_FAIL,
        })
        setAlert(errMsg, "error")
      }
    } catch (err) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }


  //Log a user Out
  const logOut = () => {
    dispatch({
      type: LOG_OUT_SUCCESS,
    })
  }


  //Request to reset a user's password
  const forgotPassword = async ({ email }) => {
    setLoading()
    try {
      const res = await AxiosRequest(
        "get",
        `api/Account/ForgotPassword?email=${email}`,
      )
      if (res.data.requestSuccessful) {
        await dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: email
        })
      // This is to cater for a second dispatch of action that is, if the user clicks resend
      window.location.pathname !== "/user/email"
        ? navigate("/user/email")
        : setAlert(`Email successfully sent to ${email}`, "success")
      }else {
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
        })
        setAlert(res.data.message, "error")
      } 
    } catch (err) {
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }


  //Reset a user's passwprd
  const resetPassword = async payload => {
    setLoading()
    try {
      const res = await AxiosRequest(
        "post",
        "/api/Account/ResetPassword",
        payload
      )
      if (res.data.requestSuccessful) {
        await dispatch({
          type: RESET_PASSWORD_SUCCESS,
        })
        navigate("/user/login")
        setAlert("Password reset successful, Login to continue", "success")
      } else {
        dispatch({
          type: RESET_PASSWORD_FAIL,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: RESET_PASSWORD_FAIL,
      })
      setAlert(errMsg, "error")
    }
  }

  const actionSuccess = () => dispatch ({ type: ACTION_SUCCESS })

  //Check for errors

  //set Loading
  const setLoading = () => dispatch({ type: SET_LOADING })

  const { user, isAuthenticated, loading, isLoading } = state
  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        loading,
        isLoading,
        Login,
        loadUser,
        logOut,
        register,
        forgotPassword,
        resetPassword,
        sendActivateEmail,
        activateUserEmail,
        updateProfile,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  )
}

export default AuthState
