import {
  GET_SETTINGS_SUCCESS,
  SET_LOADING,
  REFER_SUCCESS,
  ACTION_SUCCESS,
  ACTION_ERROR,
  FEEDBACK_SUCCESS,
  GET_USER_LOAN,
  GET_USER_LOAN_FAIL,
  GET_USER_LOAN_SUCCESS,
  GET_PROVIDERS_SUCCESS,
  HANDLE_ELIGIBILITY_PREVIOUS_STEP,
  HANDLE_ELIGIBILITY_NEXT_STEP,
  RESET_ELIGIBILITY_FORM_STEP,
  INELIGIBLE_LOAN_APPLICATION,
  ELIGIBLE_LOAN_APPLICATION,
  SET_LOAN_SUBMITTING,
} from "../types"

const loanReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        pSettings: payload,
        isLoading: false,
      }

    case ACTION_ERROR:
    case ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
      }

    case REFER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: true,
      }

    case FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feedbackStatus: true,
      }

    case GET_USER_LOAN:
      return {
        ...state,
        gettingUserLoan: true,
      }

    case GET_USER_LOAN_SUCCESS:
      return {
        ...state,
        userLoan: payload,
        gettingUserLoan: false,
      }

    case GET_USER_LOAN_FAIL:
      return {
        ...state,
        gettingUserLoan: false,
      }

    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoading: false,
        providers: payload,
      }

    case HANDLE_ELIGIBILITY_PREVIOUS_STEP:
      let activeStep = state.eligibility.activeStep - 1
      return {
        ...state,
        eligibility: { ...state.eligibility, activeStep },
      }

    case HANDLE_ELIGIBILITY_NEXT_STEP:
      return {
        ...state,
        eligibility: {
          ...state.eligibility,
          activeStep: state.eligibility.activeStep + 1,
        },
      }

    case RESET_ELIGIBILITY_FORM_STEP:
      return {
        ...state,
        eligibility: { ...state.eligibility, activeStep: 0 },
      }

    case ELIGIBLE_LOAN_APPLICATION:
      return {
        ...state,
        eligibility: { ...state.eligibility, status: true, feedBack: payload },
        isSubmitting: false,
      }

    case INELIGIBLE_LOAN_APPLICATION:
      return {
        ...state,
        eligibility: { ...state.eligibility, status: false, feedBack: payload },
        isSubmitting: false,
      }

    case SET_LOAN_SUBMITTING:
      return {
        ...state,
        isSubmitting: true,
      }

    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      }

    default:
      return state
  }
}

export default loanReducer
