import React, { useReducer, useContext } from "react"
import LoanReducer from "./loanReducer"
import LoanContext from "./loanContext"
import AxiosRequest from "../../services/AxiosRequests"
import AlertContext from "../alert/alertContext"

import {
  SET_LOADING,
  GET_SETTINGS_SUCCESS,
  REFER_SUCCESS,
  ACTION_SUCCESS,
  ACTION_ERROR,
  GET_USER_LOAN,
  GET_USER_LOAN_FAIL,
  GET_USER_LOAN_SUCCESS,
  GET_PROVIDERS_SUCCESS,
  SET_LOAN_SUBMITTING,
  HANDLE_ELIGIBILITY_PREVIOUS_STEP,
  HANDLE_ELIGIBILITY_NEXT_STEP,
  RESET_ELIGIBILITY_FORM_STEP,
  INELIGIBLE_LOAN_APPLICATION,
  ELIGIBLE_LOAN_APPLICATION,
} from "../types"
import { navigate } from "gatsby"

const LoanState = props => {
  const initialState = {
    providers: [],
    isLoading: false,
    pSettings: [],
    userLoan: null,
    gettingUserLoan: false,
    feedbackStatus: null,
    isSubmitting: false,
    eligibility: {
      activeStep: 0,
      status: false,
      feedBack: null,
    },
  }

  const [state, dispatch] = useReducer(LoanReducer, initialState)
  // const educollectEmail = "info@educollectfinance.com"
  const errMsg = "An error occured, please try again"
  // const authMsg = "Session expired, please log in to continue"

  const { setAlert } = useContext(AlertContext)

  //get All loan providers' settings
  const getProvidersSettings = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", "api/ProviderSettings")
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_SETTINGS_SUCCESS,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  //refer a friend
  const refer = async (payload, resetForm) => {
    setLoading()
    try {
      const values = { ...payload, referralDetails: payload.friends }
      const res = await AxiosRequest("post", "/api/Refer/create", values)
      if (res.data.requestSuccessful) {
        dispatch({
          type: REFER_SUCCESS,
        })
        resetForm({
          payload: {
            message: payload.message,
            userName: "",
            userEmail: "",
            friends: [""],
          },
        })
        if (
          window.location.pathname === "/dashboard/refer-a-friend/" ||
          window.location.pathname === "/dashboard/refer-a-friend"
        ) {
          navigate("/dashboard")
        }
        setAlert(
          "Thank you for the referral, message sent successfully",
          "success"
        )
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
      setAlert(errMsg, "error")
    }
  }

  //send Feedback from popup form
  const sendFeedback = async payload => {
    const values = { ...payload, comment: payload.message }
    try {
      await AxiosRequest("post", "/api/Feedback/create", values)
    } catch (error) {
      console.log(error)
    }
  }

  //get user's loan
  const getUserLoan = async payload => {
    dispatch({
      type: GET_USER_LOAN,
    })
    try {
      const res = await AxiosRequest(
        "get",
        `/api/LoanTransaction/loans/${payload}`
      )
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_USER_LOAN_SUCCESS,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: GET_USER_LOAN_FAIL,
        })
      }
    } catch (err) {
      dispatch({
        type: ACTION_ERROR,
      })
      // setAlert("Something went wrong, please refresh the page", "error")
    }
  }

  //get loan Providers
  const getProviders = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", "/api/Provider")
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_PROVIDERS_SUCCESS,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  const submitPartner = async (payload, resetForm) => {
    setSubmitting()
    try {
      const res = await AxiosRequest("post", `/api/Partner/create`, payload)
      if (res.data.requestSuccessful) {
        dispatch({
          type: ACTION_SUCCESS,
        })
        setAlert("Message submitted successfully", "success")
        resetForm()
      } else {
        setAlert(res.data.message, "error")
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      actionError(err)
    }
  }

  //submit contact
  const submitContact = async (payload, resetForm) => {
    setSubmitting()
    try {
      const res = await AxiosRequest("post", "/api/Contact", payload)
      if (res.data.requestSuccessful) {
        dispatch({
          type: ACTION_SUCCESS,
        })
        resetForm()
        setAlert("Thank you, message sent successfully", "success")
      } else {
        setAlert(res.data.message, "error")
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      actionError(err)
    }
  }

  //submit contact
  const checkEligibility = async (payload, resetForm) => {
    setSubmitting()
    try {
      const res = await AxiosRequest(
        "post",
        "/api/Eligibility/checkeligibility",
        payload
      )
      if (res.data.requestSuccessful) {
        dispatch({
          type: ELIGIBLE_LOAN_APPLICATION,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: INELIGIBLE_LOAN_APPLICATION,
          payload: res.data.message,
        })
      }
      handleEligibilityNextStep()
      resetForm()
    } catch (err) {
      actionError(err)
    }
  }

  const getPartners = async () => {
    try {
      const res = await AxiosRequest("get", "api/Organization")
      if (res.data.requestSuccessful) {
        const partners = res.data.responseData.items
        const activePartners = partners.filter(
          partner => partner.status === true
        )
        return activePartners
      }
    } catch (error) {
      throw error
    }
  }

  //handle next steps for the eligibility form
  const handleEligibilityNextStep = () =>
    dispatch({
      type: HANDLE_ELIGIBILITY_NEXT_STEP,
    })

  //hanle previous steps for the eligibility form
  const handleEligibilityPreviousStep = () =>
    dispatch({
      type: HANDLE_ELIGIBILITY_PREVIOUS_STEP,
    })

  const resetEligibilityFormStep = () =>
    dispatch({
      type: RESET_ELIGIBILITY_FORM_STEP,
    })

  //Check for errors
  //this will be handled in the axios configuration
  // const checkError = err => {
  //   if (err.response && err.response.status === 401) {
  //     logOut()
  //   } else {
  //     return setAlert(errMsg, "error")
  //   }
  // }

  const actionError = err => {
    // console.log(err)
    setAlert(errMsg, "error")
    dispatch({ type: ACTION_ERROR })
  }

  const setSubmitting = () => dispatch({ type: SET_LOAN_SUBMITTING })

  //set Loading
  const setLoading = () => dispatch({ type: SET_LOADING })

  return (
    <LoanContext.Provider
      value={{
        ...state,
        getProvidersSettings,
        getProviders,
        refer,
        sendFeedback,
        getUserLoan,
        submitPartner,
        submitContact,
        getPartners,
        checkEligibility,
        handleEligibilityPreviousStep,
        handleEligibilityNextStep,
        resetEligibilityFormStep,
      }}
    >
      <>{props.children}</>
    </LoanContext.Provider>
  )
}

export default LoanState
