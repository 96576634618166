
// General
export const ACTION_ERROR = 'ACTION_ERROR';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const SET_LOADING = 'SET_LOADING';


//Authentication
export const AUTH_METHOD = 'AUTH_METHOD';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FSIL';
export const AUTH_SUCCESS ='AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = 'RESEND_FAIL';
export const ACCOUNT_ACTIVATE_SUCCESS = 'ACCOUNT_ACTIVATE_SUCCESS';
export const ACCOUNT_ACTIVATE_FAIL = 'ACCOUNT_ACTIVATE_FAIL';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';



// Alert
export const SET_ALERT = 'SET_ALERT';

//Loans
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const REFER_SUCCESS = 'REFER_SUCCESS';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const GET_USER_LOAN_SUCCESS = 'GET_USER_LOAN_SUCCESS';
export const GET_USER_LOAN = 'GET_USER_LOAN';
export const GET_USER_LOAN_FAIL = 'GET_USER_LOAN_FAIL';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const SET_LOAN_SUBMITTING = 'SET_LOAN_SUBMITTING';


//Eligibility
export const HANDLE_ELIGIBILITY_PREVIOUS_STEP = 'HANDLE_ELIGIBILITY_PREVIOUS_STEP';
export const HANDLE_ELIGIBILITY_NEXT_STEP = 'HANDLE_ELIGIBILITY_NEXT_STEP';
export const RESET_ELIGIBILITY_FORM_STEP = 'RESET_ELIGIBILITY_FORM_STEP';
export const INELIGIBLE_LOAN_APPLICATION = 'INELIGIBLE_LOAN_APPLICATION';
export const ELIGIBLE_LOAN_APPLICATION = 'ELIGIBLE_LOAN_APPLICATION';


