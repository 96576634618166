import {
  SET_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOG_OUT_SUCCESS,
  AUTH_SUCCESS,
  AUTH_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  ACCOUNT_ACTIVATE_SUCCESS,
  ACCOUNT_ACTIVATE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  SESSION_EXPIRED,
  ACTION_SUCCESS
} from "../types"

import Cookies from "js-cookie"
import { axiosInstance } from "../../services/AxiosRequests"

const authReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case LOGIN_SUCCESS:
      const { expires, valid, user } = payload
      // localStorage.setItem("token", token)
      // localStorage.setItem("time", 180*1000)
      localStorage.setItem("time", Number(expires - 300) * 1000)
      localStorage.setItem("valid", valid)
      localStorage.setItem("email", user.email)
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        expired: false,
      }

    case REGISTER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      }

    case AUTH_SUCCESS:
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        loading: false,
      }

    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOG_OUT_SUCCESS:
    case AUTH_ERROR:
      localStorage.clear()
      Cookies.remove("token")
      delete axiosInstance.defaults.headers.Authorization
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
      }

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      }

    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
    case FORGOT_PASSWORD_FAIL:
    case RESEND_EMAIL_FAIL:
    case ACCOUNT_ACTIVATE_SUCCESS:
    case ACCOUNT_ACTIVATE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATE_PROFILE_FAIL:
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
      }

    case ACTION_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case SESSION_EXPIRED:
      return {
        ...state,
        expired: true,
      }

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

export default authReducer
